.App {
  text-align: center;
  background-color: #ffffff;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
 
  
}

.input{
  text-align:center;
  position: relative;
  
  
}
.bodyDiv{
  
}

.sizeInput{
  position: relative;
  right: 1%;
  padding: 5px;

  
}

.variableInput{
  position: relative;
  right: 2.1%;
  padding: 5px;
}

.ruleInput{
  position: relative;
  left: 9.6%;
  
}

.ruleDropDown{
  padding: 5px;
  position: relative;
  right: 8.3%;

  
}

.varRuleInput{
  position: relative;
  right: 16.9%;
  padding: 5px;
}

.ruleButton{
  position: relative;
  right: 10%;
  padding: 5px;
}



.rulesDisplay{
  display: flex;
  flex-direction: row;
  padding: 5px;
  position: relative;
  
}

.ruleDisplayed{
  
  position: relative;
  left: 42%;
  font-style: italic;
}
.removeButton{
  position: relative;
  left: 43%;
  
}

.board{

display: flex;
flex-direction: column;


}

.row{
  
  display: flex;
  flex-direction: row;
  height: 45px;
  margin: 5px;
  justify-content: center;
  
  
  
}

.letter{
  
  height: 100%;
  border-bottom: 2px solid black;
  margin: 7px;
  line-height: 63px;
  display: grid;
  place-items: center;
  font-size: 30px;
  width: 25px;
  
}



.numbering{
  font-size: 150px !important;
}

.possibleGameBoards{
  margin-top: 10px;
  font-weight: bold;
  
}

.rulesHeader{
  position: relative;
  right: 10%;
  font-weight: bold;
  margin: 10px;
}

.select{
  width: 200px;
}
.welcome{
  
  
  
}








